.bc-container {
  display: grid;
}

.bc {
  max-width: 500px;
  width: 100%;
  justify-self: center;
  border: 1px solid $accent;
  box-shadow: 8px 8px 0 $accent;

  background-color: $bg;
  background-image: url("https://www.transparenttextures.com/patterns/rice-paper-2.png");

  svg {
    max-width: 33px;
    // height: 100%;
    width: 100%;

    @include MQ(md) {
      max-width: 44px;
    }
  }

  path {
    fill: $accent;
  }

  @media (prefers-color-scheme: dark) {
    box-shadow: 8px 8px 0 $dark-accent;
    border-color: $dark-accent;
    background-color: $dark-bg;
    background-image: none;
    // color: $dark-accent;
    
    // a {
    //   color: $dark-accent;
    // }

    path {
      fill: $dark-accent;
    }
  }
}