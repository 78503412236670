.post {

  img {
    box-shadow: 8px 8px 0 $dark-blue;
  }

  blockquote {
    border-top: 2px solid $light-blue;
    border-bottom: 2px solid $light-blue;
  }
}