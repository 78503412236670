// colors
/* CSS HEX */
// --white: #f0f0f2ff;
// --red: #fb2017ff;
// --dark-blue: #0c1631ff;
// --light-blue: #1481d1ff;

// /* CSS HSL */
// --white: hsla(240, 7%, 95%, 1);
// --red: hsla(2, 97%, 54%, 1);
// --dark-blue: hsla(224, 61%, 12%, 1);
// --light-blue: hsla(205, 83%, 45%, 1);

/* SCSS HEX */
$white: #eeede8;
$red: #fb2017ff;
$dark-blue: #313638;
$light-blue: #005FA3;
$light-blue-accent: #0077CC;
$pink: #ff5d73;
$rose: #c17c74;

$bg: $white;
$color: $light-blue;
$accent: $light-blue;
$dark-bg: $dark-blue;
$dark-color: $white;
$dark-accent: $rose;
$accent2: #005FA3;
$dark-accent2: #c17c74;

$colors: (
  'white': #eeede8,
  'red': #fb2017ff,
  'dark-blue': #313638,
  'light-blue': #005FA3,
  'accent': #005FA3,
  'dark-accent': #c17c74,
  'light-blue-accent': #0077CC,
  'pink': #ff5d73,
  'rose': #c17c74
);

:root {
  @each $n, $color in $colors {
    .bg-#{$n} {
      --bg: #{$color};
      background-color: #{$color};
    }
  
    .color-#{$n} {
      --color: #{$color};
      color: #{$color};
    }
  
    --#{$n}: #{$color};
  }

}

// /* SCSS HSL */
// $white: hsla(240, 7%, 95%, 1);
// $red: hsla(2, 97%, 54%, 1);
// $dark-blue: hsla(224, 61%, 12%, 1);
// $light-blue: hsla(205, 83%, 45%, 1);

// /* SCSS RGB */
// $white: rgba(240, 240, 242, 1);
// $red: rgba(251, 32, 23, 1);
// $dark-blue: rgba(12, 22, 49, 1);
// $light-blue: rgba(20, 129, 209, 1);

/* SCSS Gradient */
// $gradient-top: linear-gradient(0deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-right: linear-gradient(90deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-bottom: linear-gradient(180deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-left: linear-gradient(270deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-top-right: linear-gradient(45deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-bottom-right: linear-gradient(135deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-top-left: linear-gradient(225deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-bottom-left: linear-gradient(315deg, #f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);
// $gradient-radial: radial-gradient(#f0f0f2ff, #fb2017ff, #0c1631ff, #1481d1ff);

// breakpoints
$xs:     340px;
$sm:     480px;
$md:     769px;
$ml:     910px;
$l:      1180px;
$xl:     1480px;

@mixin MQ($canvas) {
  @if $canvas == xs {
   @media only screen and (min-width: $xs) { @content; }
  }
  @else if $canvas == sm {
   @media only screen and (min-width: $sm) { @content; }
  }
  @else if $canvas == md {
   @media only screen and (min-width: $md) { @content; }
  }
  @else if $canvas == l {
   @media only screen and (min-width: $l) { @content; }
  }
  @else if $canvas == xl {
   @media only screen and (min-width: $xl) { @content; }
  }
}

// Burger parts
//
// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after


// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
$height: 5px;
$gutter: 3px;
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $c: $accent, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height;
  $burger-gutter: $gutter;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  @if $sass-burger-add-vendor-prefixes {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  user-select: none;

  // 1. Fixes jagged edges in Firefox, see issue #10.
  &, &::before, &::after {
    display: block;
    width: $width;
    height: $height;
    background-color: $c;
    outline: 1px solid transparent; // 1
    @if $border-radius != 0 {
        border-radius: $border-radius;
    }

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
    }
    transition-property: background-color, transform;

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-duration: $transition-duration;
      -moz-transition-duration: $transition-duration;
      -o-transition-duration: $transition-duration;
    }
    transition-duration: $transition-duration;
  }

  &::before, &::after {
    position: absolute;
    content: "";
  }

  &::before {
    top: -($height + $gutter);
  }

  &::after {
    top: $height + $gutter;
  }
}


// Select parts of the burger
@mixin burger-parts {
    &, &::before, &::after {
      @content;
    }
}

@mixin burger-top {
    &::before {
      @content;
    }
}

@mixin burger-middle {
    & {
      @content;
    }
}

@mixin burger-bottom {
    &::after {
      @content;
    }
}


// Burger animations
@mixin burger-to-cross($color: auto) {
  $burger-height: $height;
  $burger-gutter: $gutter;
  & {
    background-color: transparent;
  }
  @if ($color != auto) {
      &::before, &::after {
        background-color: $color;
      }
  }
  &::before {
      @if $sass-burger-add-vendor-prefixes {
        -webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      }
      transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &::after {
      @if $sass-burger-add-vendor-prefixes {
        -webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      }
      transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}

// Spacing Utilities
$breakpoints: (xs, sm, md, ml, l, xl);
$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15); 
$sides: (top, bottom, left, right);

@each $size in $breakpoints {
  @include MQ($size) {
    @each $space in $spaceamounts {
      @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$size}-#{$space} {
          margin-#{$side}: #{$space}rem !important;
        }
      
        .p#{str-slice($side, 0, 1)}-#{$size}-#{$space} {
          padding-#{$side}: #{$space}rem !important;
        }
      }
    }

    .float-right-#{$size} {
      float: right;
    }

    .float-left-#{$size} {
      float: left;
    }

    .text-align-#{$size}-center {
      text-align: center;
    }

    .text-align-#{$size}-right {
      text-align: right;
    }

    .text-align-#{$size}-left {
      text-align: left;
    }
  }
}

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}rem !important;
    }
  
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}rem !important;
    }
  }

  .m-#{$space} {
    margin: #{$space}rem !important;
  }

  .p-#{$space} {
    padding: #{$space}rem !important;
  }

}