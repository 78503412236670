.header-box {
  position: relative;
  border: 1px solid $accent;
  background-color: $bg;
  box-shadow: 8px 8px 0 $accent;
  
  .header {
    color: $color;
  }
  
  @media (prefers-color-scheme: dark) {
    background-color: $dark-bg;
    border-color: $dark-accent;
    box-shadow: 8px 8px 0 $dark-accent;
    
    .header {
      color: $dark-color; 
    }
  }
}

.hidden-svg {
  position: absolute;
  margin-left: -1000%;
  margin-top: -1000%;
}

.promo-tag {
  position: absolute;
  width: 190px;
  height: 135px;
  right: -45px;
  top: -15%;
  // clip-path: url("/assets/promo-inside.svg#svgPath");
  background-color: $accent;
  transform: rotate(35deg);
  color: $white;
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  scale: 0.7;
  outline: 1px solid transparent;
  
  @include MQ(md) {
    right: -80px;
    scale: 1;
    height: 140px;
    right: -100px;
    bottom: -50px;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $dark-accent;
  }
}

img {
  width: 100%;

  &.home-img {
    max-width: 320px;
    box-shadow: 8px 8px 0 $accent;
    border: 1px solid $accent;
    // padding: 1rem;
    float: left;
    
    @media (prefers-color-scheme: dark) {
      box-shadow: 8px 8px 0 $dark-accent;
      border-color: $dark-accent;
    }
  }
}

hr {
  justify-self: start;
  width: 100%;
  border-color: $accent;
  
  @media (prefers-color-scheme: dark) {
    border-color: $dark-accent;
  }
}

.blog {
  display: grid;
}

.blog-intro {
  justify-self: left;

  .header {
    display: inline;
  }

  .header-icon {
    position: absolute;
    bottom: -40px;
    left: 50%;
    padding: 1.2rem;
    background-color: $accent;
    border: 1px solid $accent;
    border-radius: 100%;
    line-height: 1;
    transform: translateX(-50%);

    @media (prefers-color-scheme: dark) {
      background-color: $dark-accent;
      border: 1px solid $dark-accent;
    }
  }
  
  svg {
    width: 30px;
    height: 30px;
  }

  path {
    fill: $white;
  }
}

.post_tile-link {
  color: inherit;
  text-decoration: none;
  display: block;
  border-left: 2px solid $accent;
  padding-left: 1rem;

  @media (prefers-color-scheme: dark) {
    border-color: $dark-accent;
  }

  h4 {
    margin-bottom: 0;
  }
}

.cookiez {
  position: fixed;
  bottom: 2rem;
  right: -100%;
  border: 1px solid $light-blue;
  background-color: $white;
  box-shadow: 8px 8px 0 $accent;
  max-width: 300px;
  transition: right 500ms ease;
  z-index: 100;
  
  @media (prefers-color-scheme: dark) {
    background-color: $dark-bg;
    box-shadow: 8px 8px 0 $dark-accent;
    border-color: $dark-accent;
  }

  &.show {
    right: 2rem;
  }
}