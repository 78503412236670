html {
  background-color: $white;
  
  ::selection {
    background: #ffb7b7;
    color: #000;
  }
  ::-moz-selection {
    background: #ffb7b7;
    color: #000;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $dark-blue;
    color: $white;

    ::selection {
      background: #eeede8;
      color: #000;
    }
  
    ::-moz-selection {
      background: #eeede8;
      color: #000;
    }
  }

  
}