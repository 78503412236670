.sitenav {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  padding: 10px;

  ul {
    list-style: none;
  }
}

.home-link {
  display: grid;

  .home-link-icon {
    display: grid;
    background-color: $accent;

    @media (prefers-color-scheme: dark) {
      background-color: $dark-accent;
    }
  }

  svg {
    max-width: 33px;
    height: 100%;
    width: 100%;

    @include MQ(md) {
      max-width: 44px;
    }
  }

  path {
    fill: $white;
  }

  a {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
    justify-self: left;
    background-color: $bg;
    border: 1px solid $accent;
    color: $color;
    text-decoration: none;
    transition: 300ms ease box-shadow;

    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      top: 0.8rem;
      left: 0.8rem;
      height: 100%;
      width: 100%;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23005fa3'/%3E%3C/svg%3E") repeat bottom right;
      transition: all 300ms;
    }
    
    &:hover {
      // box-shadow: 2px 2px 0 $pink;
      &::before {
        top: 0.3rem;
        left: 0.3rem;
      }
    }
    
    @media (prefers-color-scheme: dark) {
      color: $dark-color; 
      background-color: $dark-bg;
      border-color: $dark-accent;
      
      &::before {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3'%3E%3Ccircle cx='2.5' cy='2.5' r='0.9' fill='%23#{$dark-accent2}'/%3E%3C/svg%3E") repeat bottom right;
      }
    }

  }
}

.sitenav__links {
  display: none;

  @include MQ(md) {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-gap: 1rem;
    align-items: center;
    margin: 0;
  }

  li {
    position: relative;
    min-width: 100px;
    margin: 0;
    
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: $color;
      transition: color 300ms ease;

      &:hover {
        text-decoration: underline;
      }

      @media (prefers-color-scheme: dark) {
        color: $dark-color;

        &:hover {
          color: lighten($dark-color, 15%)
        }
      }
    }

    &.active a {
      text-decoration: underline;
    }
  }
}

.offcanvas {
  position: fixed;
  z-index: 250;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 250px;
  height: 100vh;
  padding: 2rem;
  background-color: $accent;
  transform: translateX(100%);
  transition: transform 350ms ease;
  overflow-y: scroll;
  
  &.active {
    transform: translateX(0);
  }
  
  & * {
    color: $white;
  }


  .offcanvas__content {
    position: relative;
    min-height: 100%;
  }

  .close {
    width: 120px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .contact-details {
    padding-bottom: 0.5em;
  }

  a, h5 {
    text-decoration: none;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $dark-accent;
    color: $dark-color;
  }
}

// .mobile-sitenav__link {
// }

.menu-button {
	display: inline-block;
	padding: .5rem;
  
  @include MQ(md) {
    display: none;
  }
}

.burger-icon {
	@include burger(25px, 3px, 5px, $accent);
  
  @media (prefers-color-scheme: dark) {
    @include burger(25px, 3px, 5px, $dark-accent);
  }
}

.menu-button.is-active .burger-icon {
	@include burger-to-cross;
}


// Mobile menu animation
body {
  transition: transform 300ms ease;
  
  &.menu-open {
    transform: translateX(-250px);
  }
}



// Dropdown sub menu navigation
.sub-menu-parent {
  position: relative;
}

.sub-menu { 
  visibility: hidden; /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 1rem 0 0 0;
  margin: 0;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

  li {
    margin-bottom: 1rem;
  }
}

.sub-menu-parent:focus .sub-menu,
.sub-menu-parent:focus-within .sub-menu,
.sub-menu-parent:hover .sub-menu {
  visibility: visible; /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
}

footer {
  border-top: 1px solid $light-blue;

  @media (prefers-color-scheme: dark) {
    border-color: $dark-accent;
  }
}